<template>
  <div>
    <CRow>
      <CCol>
        <CAlert show color="info" v-if="habilitaDimensoes()">
          Por padrão, o usuário pode vender todo e qualquer segmento. Ao aplicar regras de bloqueio, o segmento não
          será mais exibido para venda.
        </CAlert>
        <CAlert show color="warning" v-if="!habilitaDimensoes()">
          Caso a opção <b>"Utiliza restrições de segmento de venda do supervisor"</b> da aba <b>"Dados do Usuário"</b> estiver marcada ou os parâmetros <b>"Validar grupo de segmento de venda."</b> estiverem inativos, os segmentos não estarão visíveis aqui.
        </CAlert>
      </CCol>
    </CRow>
    <CRow v-if="habilitaDimensoes()">
      <div class="col-sm-5">
        <select class="form-control" v-model="labelCategoria" v-on:change="debounceLoadDimensoes()">          
          <option :value="null">Escolha um segmento ...</option>
          <option v-for="i in items" v-bind:value="i.label" v-bind:key="i.label">{{ i.label }}</option>
        </select>
      </div>
      <div class="col">
        <CButton color="danger" class="mr-1" @click="checkAll(itemsFilter)" :disabled="!labelCategoria">
          Bloquear Todas
        </CButton>
        <CButton color="success" class="mr-1" @click="unCheckAll(itemsFilter)" :disabled="!labelCategoria">
          Liberar Todas
        </CButton>
      </div>
    </CRow>
    <CDataTable v-if="habilitaDimensoes()"
      :items="itemsFilter"
      :fields="fields"
      table-filter
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      :pagination="{ doubleArrows: true, align: 'center'}"
      :tableFilter="{label: 'Filtrar por:', placeholder: '...'}"
      :noItemsView="{noItems: 'Nada encontrado'}"
      :key="keyDataTable"
    >
      <template #status="{item}">
        <td>
          <label class="c-switch c-switch-sm c-switch-3d c-switch-danger">
            <input v-if="testItemChecked(item.id)" type="checkbox" class="c-switch-input" name="check" :checked="testItemChecked(item.id)"
              @change="atualizaDimensao(colaborador.id, item.id, 0, colunaDimensaoCategoria)"
              :id="'checkbox' + colaborador.id + '' + item.id + '' + colunaDimensaoCategoria">
            <input v-else-if="!testItemChecked(item.id)" type="checkbox" class="c-switch-input" name="UnCheck" :checked="testItemChecked(item.id)"
              @change="atualizaDimensao(colaborador.id, item.id, 1, colunaDimensaoCategoria)"
              :id="'checkbox' + colaborador.id + '' + item.id + '' + colunaDimensaoCategoria">
            <span class="c-switch-slider"></span>
          </label>
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>

const fields = [
  { 
    key: 'status',
    sorter: true, 
    filter: false
  },
  {
    key: 'id',
    label: 'ID',
    sorter: true, 
    filter: false
  },
  {
    key: 'desc',
    label: 'Descrição',
    sorter: true, 
    filter: false
  },
]

export default {
  name: 'TableDimensoesSegmentoVenda',
  props: [
    'items',
    'colaborador',
    'upInsertDimensao',
    'dimensoesBloqueadasSegmentoVenda',
    'insertAllDimensao',
    'deleteAllDimensao',
    'parametros'
  ],
  data () {
    return {
      fields,
      labelCategoria: null,
      itemsFilter: null,
      colunaDimensaoCategoria: null,
      keyDataTable: 0
    }
  },
  methods: {
    debounceLoadDimensoes(reloadTable = true){
      if(this.labelCategoria != null){
        var resultado                 = this.items.find(dimensao => dimensao.label == this.labelCategoria);
        this.colunaDimensaoCategoria  = resultado.coluna;
        var dimensoesTransformed      = [];
        
        if(reloadTable){
          this.keyDataTable++;
        }        

        var contador = 0, testeDimensaoBloqueada = null;
        resultado.dimensoes.forEach(element => {
          testeDimensaoBloqueada = this.dimensoesBloqueadasSegmentoVenda.find(dimensaoBloqueada => 
                                                                                dimensaoBloqueada.id_dimensao_bloqueada == element.id 
                                                                                && dimensaoBloqueada.coluna_dimensao_bloqueada == this.colunaDimensaoCategoria);
          if(testeDimensaoBloqueada != null){
            element.status        = 1;
            element.coluna        = this.colunaDimensaoCategoria;
            element.colaboradorId = this.colaborador.id;
          }else{
            element.status        = 0;
            element.coluna        = this.colunaDimensaoCategoria;
            element.colaboradorId = this.colaborador.id;
          }
          
          dimensoesTransformed[contador] = element;
          contador++;
        });

        this.itemsFilter = dimensoesTransformed;
      }
    },
    checkAll(itensForChecked){
      this.insertAllDimensao(itensForChecked);
    },
    unCheckAll(itensForChecked){
      this.deleteAllDimensao(itensForChecked);
    },
    atualizaDimensao(colaboradorId, dimensaoId, status, colunaDimensaoCategoria){
      this.upInsertDimensao(colaboradorId, dimensaoId, status, colunaDimensaoCategoria);
      this.debounceLoadDimensoes(false);
    },
    testItemChecked(itemId){
      var test = false;
      test = this.dimensoesBloqueadasSegmentoVenda.find(dimensaoBloqueada => 
                                                    dimensaoBloqueada.id_dimensao_bloqueada == itemId 
                                                    && dimensaoBloqueada.coluna_dimensao_bloqueada == this.colunaDimensaoCategoria);
      if(test != null){
        return true;
      }
      return false;
    },
    habilitaDimensoes() {
      const CALLCENTER = this.parametros.CALLCENTER_VALIDA_SEGMENTO_VENDA.padrao;
      const FORCA      = this.parametros.FORCA_VENDAS_VALIDA_SEGMENTO_VENDA.padrao;
      const SUPERV     = this.colaborador.usaRestricaoSegmentoVendaSupervisor;

      if (SUPERV != 1 && (CALLCENTER == 1 || FORCA == 1)) {
        return true;
      }

      return false;
    }
  }
}
</script>