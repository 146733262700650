<template>
  <div>
    <CRow>
      <CCol>
        <CAlert show color="info" v-if="habilitaClientes()">
          Ao aplicar regras de bloqueio de segmento, elas passarão a ser aplicadas em todos os clientes, exceto nos
          clientes liberados abaixo.
        </CAlert>
        <CAlert show color="warning" v-if="!habilitaClientes()">
          Caso os parâmetros <b>"Validar grupo de segmento de venda."</b> estiverem inativos, os clientes não estarão visíveis aqui.
        </CAlert>
      </CCol>
    </CRow>
    <CRow v-if="habilitaClientes()">
      <div class="col-sm-5">
        <CButton color="danger" class="m-2" @click="unCheckAll(items, colaborador.id)" :disabled="items.length <= 0">
          Bloquear Todos
        </CButton>
        <CButton color="success" class="m-2" @click="checkAll(items, colaborador.id)" :disabled="items.length <= 0">
          Liberar Todos 
        </CButton>
      </div>
    </CRow>
    <CDataTable v-if="habilitaClientes()"
      :items="items"
      :fields="fields"
      table-filter
      items-per-page-select
      :items-per-page="10"
      hover
      sorter
      :pagination="{ doubleArrows: true, align: 'center'}"
      border
      :tableFilter="{label: 'Filtrar por:', placeholder: '...'}"
      :noItemsView="{noItems: 'Nada encontrado'}"
    >
      <template #status="{item}">
        <td>
          <label class="c-switch c-switch-sm c-switch-3d c-switch-success">
            <input v-if="testItemChecked(item.id)" type="checkbox" class="c-switch-input" name="checkCliente" :checked="testItemChecked(item.id)"
              @change="upInsertClienteExcecao(colaborador.id, item.id, 0)">
            <input v-else-if="!testItemChecked(item.id)" type="checkbox" class="c-switch-input" name="checkCliente" :checked="testItemChecked(item.id)"
              @change="upInsertClienteExcecao(colaborador.id, item.id, 1)">
            <span class="c-switch-slider"></span>
          </label>
        </td>
      </template>
    </CDataTable>
  </div>
</template>

<script>
const fields = [
  {
    key: 'status',
    sorter: true, 
    filter: false 
  },
  { 
    key: 'id',
    label: 'ID',
    sorter: true, 
    filter: false 
  },
  {
    key: 'fant',
    label: 'Nome',
    sorter: true, 
    filter: false 
  },
]

export default {
  name: 'TableClientesSegmentoVenda',
  props: [
    'items',
    'colaborador',
    'upInsertClienteExcecao',
    'insertAllClientes',
    'deleteAllClientes',
    'parametros'
  ],
  data () {
    return {
      fields,
    }
  },
  methods: {
    checkAll(itensForChecked, colaboradorId){
      this.insertAllClientes(itensForChecked, colaboradorId);
    },
    unCheckAll(itensForChecked, colaboradorId){
      this.deleteAllClientes(itensForChecked, colaboradorId);
    },
    testItemChecked(itemId){
      var test = false;
      test = this.items.find(clientes => 
                                clientes.id == itemId 
                                && clientes.status == 1);
      if(test != null){
        return true;
      }      
      return false;
    },
    habilitaClientes() {
      const CALLCENTER = this.parametros.CALLCENTER_VALIDA_SEGMENTO_VENDA.padrao;
      const FORCA      = this.parametros.FORCA_VENDAS_VALIDA_SEGMENTO_VENDA.padrao;

      if (CALLCENTER == 1 || FORCA == 1) {
        return true;
      }

      return false;
    }
  }
}
</script>