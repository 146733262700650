<template>
  <tr>
    <td>
      #{{ colaborador.id }} <br>
      <small v-if="!!colaborador.codTipo">
        Cód: {{ colaborador.codTipo }}
      </small>
    </td>
    <td>
      <strong>{{ colaborador.nome }}</strong> <br>
      <small>{{ colaborador.email }}</small>
    </td>
    <td>
      <strong>{{ colaborador.tipoDescricao }}</strong> <br>
      <small>{{ colaborador.tipo }}</small>
    </td>
    <td>{{ colaborador.perfilDesc }}</td>
    <td>{{ colaborador.dtCriacao }}</td>
    <td>{{ colaborador.dtAtualizacao }}</td>
    <td :inner-html.prop="colaborador.ativo | booleanHtml" />
    <td :inner-html.prop="colaborador.licenciado | booleanHtml" />
    <td class="actions">
      <button
        v-if="!!openEdit"
        type="button"
        class="button button-primary"
        v-on:click="openEdit(colaborador)"
      >
        <fa-icon icon="pencil-alt" />
      </button>
      &nbsp;
      <button
        v-if="!!deleteConfirm"
        type="button"
        name="button"
        v-on:click="deleteConfirm(colaborador)"
        class="button button-error"
      >
        <fa-icon icon="trash-alt" />
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "colaboradorColaborador",
  props: [
    'colaborador',
    'openEdit',
    'deleteConfirm'
  ]
}
</script>