<template>
  <div>
    <vue-markdown :source="detalhes"/>
  </div>
</template>

<script>
  import { get } from '../../helpers/apiRequest'
  import VueMarkdown from 'vue-markdown'

    export default {
        name: "DetalhesCliente",
        props: ['cliente'],
        components: {
            VueMarkdown
        },
        data() {
          return {
              detalhes: ""
          }
        },
        methods: {
            loadDetalhes() {
               get(`/admin/clientes/${+this.cliente.id}/detalhes`)
                .then(res => this.detalhes = res.data)
            },
        },
        beforeMount() {
            this.loadDetalhes()
        }
    }
</script>